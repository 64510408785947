.page_processing_modal {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    height: 100vh !important;
    background: rgba(59, 59, 59, 0.74);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 199760009 !important;
    transition: 400ms ease;
  }
  .page_processing_modal p {
    position: fixed !important;
    font-size: 17px;
    font-weight: bold;
    margin-top: 140px !important;
    /* margin-bottom: 19px; */
    /* margin-left: 14px; */
    /* color: #ffffff !important; */
    text-align: center;
  }
  




@keyframes ldio-sezv7dtj4zh {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-sezv7dtj4zh div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-sezv7dtj4zh linear 1s infinite;
  background: #5cbf22;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-sezv7dtj4zh div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #5cbf22;
}.ldio-sezv7dtj4zh div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #5cbf22;
}
.loadingio-spinner-spinner-6pgwpb38cth {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  /* background: #ffffff; */
}
.ldio-sezv7dtj4zh {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-sezv7dtj4zh div { box-sizing: content-box; }
