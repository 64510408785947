tbody tr:nth-of-type(odd) td{
  background: #f7f7f7!important;
}
.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker-wrapper input{
  border: 1px solid #d1d1d1;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  width: 100%;
}
.react-datepicker-popper{
  width: 100%;
}
